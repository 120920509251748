// 用户模块管理
import { Request } from '@/http/request'

// 用户模块管理列表
export function userModuleList(parameter: any) {
    return Request.axiosInstance.post('/web/user-app/list',parameter)
}
// 用户模块增添
export function addUserModule(parameter: any) {
  return Request.axiosInstance.post('/web/user-app/add',parameter)
}
// 用户模块删除
export function deleteUserModule(parameter: any) {
    return Request.axiosInstance.post('/web/user-app/delete',parameter)
}
// 用户模块修改
export function editUserModule(parameter: any) {
    return Request.axiosInstance.post('/web/user-app/edit',parameter)
}
// 用户自主开通模块
export function selfAddUserModule(parameter: any) {
    return Request.axiosInstance.post('/web/user-app/self-add',parameter)
}

export default { userModuleList, addUserModule, deleteUserModule, editUserModule, selfAddUserModule }