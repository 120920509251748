// 开通模块
<template>
    <div class="shop">
        <div class="showBox">
            <div>
                <div class="flex-start">
                    <span class="font-item">模块名：</span>
                    <span class="font-item">{{ moduleList.name }}</span>
                    <span class="left-item">
                        <icon-font type="icon-weikaiqi" class="img-icon"/>
                    </span>
                </div>
                <!-- <div class="flex-start">
                    <span class="font-item">价格：</span>
                    <span class="font-item">{{ moduleList.price == 0?'免费':'￥'+moduleList.price }}</span>
                </div> -->
                <div class="flex-start top-item">
                    <!-- <span class="font-item">备注：</span> -->
                    <span class="font-item">{{ moduleList.remark }}</span>
                </div>
                <div class="flex-start top-item">
                    <span class="font-item-other">你还未开通此模块，请点击开通！</span>
                    <el-button class="confirmAdd left-item" round @click="openModule">
                        <i class="el-icon-loading" v-if="iconLoading"></i>
                        开通
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { IconFont } from "@/utils/iconfont"
import userModule from '@/api/web/userModule'
import app from '@/api/web/app'
import emitter from '@/utils/bus.js'; 
export default {
    inject:['reload'],
    components: {
        IconFont
    },
    props:{
        appId:{
            default: '0'
        }
    },
    data () {
        return {
            moduleList:{},
            iconLoading:false,
            newAppId:this.appId
        }
    },
    created() {

    },
    watch: {
    //   '$route' (to, from) {
    //     //   console.log(to)
    //       if(to.name == 'openModule'){
    //           this.getModuleData()
    //       }
    //   }
        appId(news,olds){
            this.getModuleData(news)
        }
    },
    mounted() {
        this.getModuleData(this.newAppId)
    },
    methods: {
        // 查询模块
        getModuleData(id){
            app.getAppData({
                id:id
            }).then(res => {
                this.moduleList = res.data 
            })
        },
        // 开通模块
        async openModule(){
            // this.$notify({
            //     title: '成功',
            //     dangerouslyUseHTMmessage: '模块开通成功',
            //     type: 'success'
            // });
            // this.$router.push({
            //     path: '/dashboard',
            //     query:{
            //         go:'true'
            //     }
            // })
            let appType = this.$store.getters.user.certType
            if( appType != 0){
                this.iconLoading = true
               await userModule.selfAddUserModule({
                    id:this.newAppId
                }).then(res => {
                    // this.reload()
                    this.$notify({
                        title: '成功',
                        message: '模块开通成功',
                        type: 'success'
                    });
                    this.iconLoading = false
                    this.$emit('fatherMethod')
                    emitter.emit('refresh', 'ok')
                }).catch((err) =>{
                   this.iconLoading = false
                })
            }else{  
                this.$notify({
                    title: '请先认证',
                    message: '认证通过后才能开通模块',
                    type: 'warning'
                });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
// .shop{
//     height: calc(100vh - 135px);
//     overflow-y: overlay;
//     padding-right: 18px;
// }
.showBox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.img-icon{
    font-size: 64px;
}
.font-item{
    color: #303133;
    font-size: 18px;
    font-weight: 700;
}
.font-item-other{
    color: #606266;
    font-size: 14px;
}
.flex-between{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.flex-start{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.left-item{
    margin-left: 1rem;
}
.top-item{
    margin-top: 1rem;
}
</style>